<template>
  <div id="app">
    <h2 class='no-print'>Coin Maker Infinity</h2>
    <table>
      <tr class='no-print'>
        <td>
          <BUTTON @click.stop="doPrint()">Print Preview</BUTTON>
          &nbsp;
          <BUTTON @click.stop="convertToSvg()">SVG</BUTTON>
          &nbsp;
          <BUTTON @click.stop="saveStoreDataToFile()">Save</BUTTON>
          &nbsp;
          <BUTTON @click.stop="doLoad()">Load</BUTTON>
          &nbsp;
          <BUTTON @click.stop="showSettings()">Settings</BUTTON>
          &nbsp;
          <BUTTON @click.stop="clear()">Clear</BUTTON>
        </td>
      </tr>
      <tr style="vertical-align:top;">
        <td>
          <SmSheet :sheetUUID="'one'"/>
        </td>
        <td class='no-print'>
          <SmPropertiesPlaceholder :is="propertiesComponentName" v-if="propertiesComponentName"/>
          <SmSettings v-if="showSettingsFlag"/>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapState,mapActions,mapMutations } from 'vuex'
import SmSheet from './components/SmSheet'
import SmPropertiesPlaceholder from './components/SmPropertiesPlaceholder'
import SmDetailProperties from './components/SmDetailProperties'
import SmSettings from './components/SmSettings'

export default {
  name: 'App',
  components: {
    SmSheet,
    SmPropertiesPlaceholder,
    SmDetailProperties,
    SmSettings
  },
  computed: {
    ...mapState(['propertiesComponentName','showSettingsFlag'])
  },
  methods: {
    ...mapActions(['saveStoreDataToFile','loadStoreDataFromFile','convertToSvg']),
    ...mapMutations(['showSettings','clearData']),
    doLoad(){
      this.loadStoreDataFromFile((loadedData) => {
        this.$store.commit('updateStoreData', loadedData);
      });
    },
    doPrint(){
      window.print();
    },
    clear(){
      if( confirm('Are you sure you want to clear all data?') ){
        this.clearData();
      }
    }
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  margin-top: 10px;
}

button {
  width : 8.5em;
}
</style>
