<template>
  <div class="sheet" :class="{ 'highlighted': sheetUUID === propertiesItemUUID }" @click.stop="callSetProperties('SmSheetProperties',sheetUUID)">
    <span class="country">{{sheet.country}}</span>
  <!--
    <SmControls @add-group="handleAddGroup"/>
    -->
    <SmGroup v-for="groupUUID in sheet.groups" :groupUUID="groupUUID" :key="groupUUID"/>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
//import SmControls from './SmControls'
import SmGroup from './SmGroup'

export default {
  name: 'SmSheet',
  components: {
//    SmControls,
    SmGroup
  },
  computed: {
    ...mapState(['sheets','propertiesItemUUID']),
    sheet(){
      return this.sheets[this.sheetUUID]
    }
  },
  methods: {
    ...mapMutations(['addGroup','removeGroup','setPropertiesComponent']),
    callSetProperties( componentName, itemUUID ){
      this.setPropertiesComponent( {componentName, itemUUID} )
    }
  },
  props: {
    sheetUUID: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media screen {
  .sheet {
    height : 10.377in;
    width  : 8in;
    padding : .5625in .5in;
    box-sizing : border-box;
    border: solid 1px black;
    position: relative;
  }
}
@media print {
  .sheet {
    height : 10.377in;
    width  : 8in;
    padding : .5625in .5in;
    box-sizing : border-box;
    border: solid 1px white;
    position: relative;
  }
}

.background {
  height: 100%;
  width: 100%;
  position: absolute;
  top:0px;
  left:0px;
  z-index:-1;
}
.country {
  font-weight: bold;
  font-size: 16px;
  margin-top: .5625in;
  font-family : 'Palab';
}

</style>
