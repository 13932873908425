
// eslint-disable-next-line no-unused-vars
export function addClassToElements(selector, className) {
  document.querySelectorAll(selector).forEach(element => {
    element.classList.add(className);
  });
}

// eslint-disable-next-line no-unused-vars
export function removeClassFromElements(selector, className) {
  document.querySelectorAll(selector).forEach(element => {
    element.classList.remove(className);
  });
}
