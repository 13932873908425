<template>
  <div class="settings">
    <h2>Global Settings</h2>

    <h3>Defaults (Used when creating new)</h3>
    <table style="width:100%;">
      <tr><td colspan=2 class="header">Detail</td></tr>
      <tr>
        <td class="prompt_right">Value:</td>
        <td>
          <input v-model="detail.value"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Color:</td>
        <td>
          <input v-model="detail.color"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Snum::</td>
        <td>
          <input v-model="detail.snum"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Value:</td>
        <td>
          <input v-model="detail.value"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Height:</td>
        <td>
          <input v-model="detail.height"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Width:</td>
        <td>
          <input v-model="detail.width"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Horizontal margin:</td>
        <td>
          <input v-model="detail.horizontal_margin"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Vertical margin:</td>
        <td>
          <input v-model="detail.vertical_margin"/>
        </td>
      </tr>

    </table>

    <table>
      <tr>
        <td colspan=2 class="header">Detail Layout</td>
      </tr>
      <tr>
        <td>
          <SmDetailLayout/>
        </td>
        <td>
          <div>field a</div>
          <div>field b</div>
          <div>field c</div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapState }   from 'vuex';
import SmDetailLayout from './SmDetailLayout'

export default {
  name: 'SmSettings',
  components: {
    SmDetailLayout
  },
  computed: {
    ...mapState(['settings']),
    detail(){
      return this.settings.defaults.detail
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.settings {
  background-color : #EE8833;
  color            : white;
  padding          : 10px;
  border           : solid 1px black;
}
.header {
  text-align      : center;
  font-size       : 16px;
  font-weight     : bold;
  text-decoration : underline;
}
</style>
