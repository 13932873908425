<template>
  <div class="group" :class="{ 'highlighted': groupUUID === propertiesItemUUID }" @click.stop="callSetProperties('SmGroupProperties',groupUUID)">
    <div>
      <template v-for="detailUUID in group.details" :key="detailUUID">
        <SmDetailDetailed v-if="shouldRenderDetailed(detailUUID)" :detailUUID="detailUUID"/>
        <SmDetail v-else :detailUUID="detailUUID"/>
      </template>
    </div>
    <RemoveBtn @click.stop="removeGroup(groupUUID)"/>
    <CloneBtn @click.stop="cloneGroup(groupUUID)"/>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import SmDetail             from './SmDetail'
import SmDetailDetailed     from './SmDetailDetailed'
import RemoveBtn            from './RemoveBtn'
import CloneBtn             from './CloneBtn'

export default {
  name: 'SmGroup',
  components: {
    SmDetail,
    SmDetailDetailed,
    RemoveBtn,
    CloneBtn,
  },
  computed: {
    ...mapState(['groups','details','propertiesItemUUID']),
    group(){
      return this.groups[this.groupUUID]
    },
  },
  methods: {
    ...mapMutations(['addDetail','removeGroup','setPropertiesComponent','cloneGroup']),
    callSetProperties( componentName, itemUUID ){
      this.setPropertiesComponent( {componentName, itemUUID} )
    },
    shouldRenderDetailed(itemUUID){
      if( this.details[itemUUID].style.mode == 'detailed' ){
        return true;
      }
      return false;
    }
  },
  props: {
    groupUUID: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media print {
  .group {
    box-sizing     : border-box;
    padding        : 10px;
    border         : none;
    vertical-align : middle;
    margin-top     : auto;
    margin-bottom  : auto;
    position       : relative;
    min-height     : 60px;
  }
}

@media screen {
  .group {
    box-sizing     : border-box;
    padding        : 10px;
    border         : solid 2px #F2F2F2; 
    vertical-align : middle;
    margin-top     : auto;
    margin-bottom  : auto;
    position       : relative;
    min-height     : 60px;
  }
}
</style>

