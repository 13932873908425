<template>
  <div class="detail" :class="{ 'highlighted': detailUUID === propertiesItemUUID }" @click.stop="callSetProperties('SmDetailProperties',detailUUID)" :style="computedStyles">
    <div class="container">
      <div class="left">
        <div>
          <img v-if="detail.data.numista_pic_src_front!=undefined" :src="detail.data.numista_pic_src_front" class="coin"/>
          <div v-if="detail.data.numista_pic_src_front==undefined" class="coin"></div>
        </div>
        <div>
          <img v-if="detail.data.numista_pic_src_back!=undefined" :src="detail.data.numista_pic_src_back" class="coin"/>
          <div v-if="detail.data.numista_pic_src_back==undefined" class="coin"></div>
        </div>
      </div>
      <div class="right">
        <table>
          <tr><td class="prompt_right">Country:</td>    <td class="data">{{detail.data.location}}</td></tr>
          <tr><td class="prompt_right">Value:</td>      <td class="data">{{detail.data.value}}</td></tr>
          <tr><td class="prompt_right">Mint:</td>       <td class="data">{{detail.data.mint_desc}}</td></tr>
          <tr><td class="prompt_right">Date:</td>       <td class="data">{{detail.data.date}}</td></tr>
          <tr><td class="prompt_right">Weight:</td>     <td class="data">{{detail.data.weight}}</td></tr>
          <tr><td class="prompt_right">Diameter:</td>   <td class="data">{{detail.data.diameter}}{{detail.data.diameter_units}}</td></tr>
          <tr><td class="prompt_right">Composition:</td><td class="data">{{detail.data.composition}}</td></tr>
          <tr><td class="prompt_right">Catalog Num:</td><td class="data">{{detail.data.catalogue_num}}</td></tr>
        </table>
      </div>
      <RemoveBtn @click.stop="removeDetail(detailUUID)" style="top:25px;"/>
      <CloneBtn  @click.stop="cloneDetail(detailUUID)" style="bottom:20px;"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import RemoveBtn from './RemoveBtn'
import CloneBtn from './CloneBtn'
export default {
  name: 'SmDetailDetailed',
  components: {
    RemoveBtn,
    CloneBtn
  },
  computed: {
    ...mapState(['details','propertiesItemUUID']),
    detail(){
      return this.details[this.detailUUID]
    },
    computedStyles(){
      console.log('....styles');
      return {
        '--detail-height' : (this.detail.style.height||2) + 'in',
        '--detail-width' : (this.detail.style.width||2) + 'in',
        '--coin-diameter' : (this.detail.data.diameter + '' + this.detail.data.diameter_units),
      };
    }
  },
  methods: {
    ...mapMutations(['removeDetail','setPropertiesComponent','cloneDetail']),
    callSetProperties( componentName, itemUUID ){
      this.setPropertiesComponent( {componentName, itemUUID} )
    },
  },
  props: {
    detailUUID: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.detail {
  width          : 100%;
  min-height     : var(--detail-height);
  border-style   : dashed;
  border-width   : 1px;
  vertical-align : middle;
  display        : inline-block;
  position       : relative;
  cursor         : pointer;
  font-size      : small;
  padding        : 5px;
}

.container {
  display : flex;
  flex-direction : row;
  height : 100%;
  width : 100%;
  position : relative;
}

.left {
  text-align : left;
}

.right {
  text-align : right;
}

img.coin {
  width : var(--coin-diameter);
  height : var(--coin-diameter);
}

div.coin {
  border-radius : 50%;
  background-color : gray;
  margin : auto;
  width : var(--coin-diameter);
  height : var(--coin-diameter);
}

@media print {
  .coin {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: gray;
  }
}

</style>
