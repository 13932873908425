<template>
  <div class="properties_placeholder">
    <component :is="propertiesComponentName" :propName="propertiesItemUUID"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import SmDetailProperties from './SmDetailProperties'
import SmSubHeaderProperties from './SmSubHeaderProperties'
import SmSheetProperties from './SmSheetProperties'
import SmGroupProperties from './SmGroupProperties'

export default {
  name: 'SmPropertiesPlaceholder',
  components: {
    SmDetailProperties,
    SmSubHeaderProperties,
    SmSheetProperties,
    SmGroupProperties,
  },
  computed: {
    ...mapState(['propertiesComponentName','propertiesItemUUID'])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.properties_placeholder {
  background-color : #3399FF;
  color            : white;
  padding          : 10px;
  border           : solid 1px black;
}
</style>
