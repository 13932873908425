import { createStore } from 'vuex';

//// Helper functions
// eslint-disable-next-line no-unused-vars
function uuid(type){
  return 'coin_maker_' + type + '_' + new Date().getTime() + Math.floor(Math.random()*1000);
}
// eslint-disable-next-line no-unused-vars
function removeItemAll(arr, value) {
  var i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
}

// eslint-disable-next-line no-unused-vars
function cloneBase( state, itemUUID, type ){
  var table          = state[type + 's'];
  var newItemUUID    = uuid(type);
  while( table[newItemUUID] !== undefined ){
    newItemUUID = uuid(type);
  }
  var newItem        = Object.assign({},table[itemUUID]);
  table[newItemUUID] = newItem;
  return( newItemUUID );
}

// eslint-disable-next-line no-unused-vars
function parseCSV(csvData){
  const lines = csvData.split('\n');
  const headers = lines[0].split(',');
  const result = [];

  for (let i = 1; i < lines.length; i++) {
    const line = lines[i].split(',');
    if (line.length !== headers.length) {
      console.error('CSV data is malformed.');
      return null;
    }

    const entry = {};
    for (let j = 0; j < headers.length; j++) {
      entry[headers[j]] = line[j];
    }
    result.push(entry);
  }

  return result;
}

// eslint-disable-next-line no-unused-vars
function getElemByText( parentElem, tag, text ){
  var elems = parentElem.querySelectorAll(tag);
  for( var i=0; i<elems.length; i++ ){
    var elem = elems[i];
    if( elem.textContent.includes(text) )
      return elem;
  }
}

// eslint-disable-next-line no-unused-vars
function getClosestHolder( holders, diameter ){
  for( var i=0; i<holders.length; i++ ){
    console.log("holder:"+holders[i]+",dia:"+diameter);
    if( holders[i]-2 > diameter )
      return( holders[i] );
  }
}

// eslint-disable-next-line no-unused-vars
function generateSVG() {
  // Create an SVG element
  const svgNS = 'http://www.w3.org/2000/svg';
  const svg = document.createElementNS(svgNS, 'svg');
  console.log('creating svg');

  // Find the sheet element
  const sheet = document.querySelector('.sheet');

  // Get the offset of the sheet
  const sheetRect = sheet.getBoundingClientRect();
  const sheetX = sheetRect.left;
  const sheetY = sheetRect.top;

//  svg.setAttribute('width', sheetRect.width + 'px' );
//  svg.setAttribute('height', sheetRect.height + 'px' );
  svg.setAttribute('width',  '8.5in' );
  svg.setAttribute('height', '11in' );

  // Create a path element for the sheet
  const sheetPath = document.createElementNS(svgNS, 'path');
  // Set the path data for a 1px stroked line rectangle
  sheetPath.setAttribute('d', `M 0 0 h ${sheetRect.width} v ${sheetRect.height} h -${sheetRect.width} v -${sheetRect.height}`);
  // Set the stroke color and width
  sheetPath.setAttribute('stroke', 'black');
  sheetPath.setAttribute('stroke-width', '1');
  sheetPath.setAttribute('fill', 'white' );
  // Append the detail path to the SVG
  svg.appendChild(sheetPath);

  // Create a path along the top edge
  const path = document.createElementNS(svgNS, 'path');
  path.setAttribute('d', 'M0,0 H400');
  path.setAttribute('fill', 'white');
  path.setAttribute('stroke', 'black');
  path.setAttribute('stroke-width', '1');
  svg.appendChild(path);
  
  // Calculate and add bumps (circles) at 1-inch intervals
  for (let x = 0; x <= 8; x += 1) {
    const circle = document.createElementNS(svgNS, 'circle');
    circle.setAttribute('cx', x + 'in');
    circle.setAttribute('cy', '10px');
    circle.setAttribute('r', '5px');
    circle.setAttribute('fill', 'black');
    svg.appendChild(circle);
  }

  // Loop through all the group elements
  const groupElements = document.querySelectorAll('.group');
  groupElements.forEach((groupElement) => {
    // Get the offset of the group
//    const groupRect = groupElement.getBoundingClientRect();
//    const groupX = groupRect.left - sheetX;
//    const groupY = groupRect.top - sheetY;

    // Loop through all the detail elements in the group
    const detailElements = groupElement.querySelectorAll('.detail');
    detailElements.forEach((detailElement) => {
      // Get the offset of the detail
      const detailRect = detailElement.getBoundingClientRect();
      const detailX = detailRect.left - sheetX;
      const detailY = detailRect.top - sheetY;

      // Create a path element for the detail
      const detailPath = document.createElementNS(svgNS, 'path');
      // Set the path data for a 1px stroked line rectangle
      detailPath.setAttribute('d', `M ${detailX} ${detailY} h 2in v 2in h -2in v -2in`);
      //detailPath.setAttribute('d', `M ${detailX} ${detailY} h ${detailRect.width} v ${detailRect.height} h -${detailRect.width} v -${detailRect.height}`);
      // Set the stroke color and width
      detailPath.setAttribute('stroke', 'black');
      detailPath.setAttribute('stroke-width', '1');
      detailPath.setAttribute('fill', 'white' );
      // Append the detail path to the SVG
      svg.appendChild(detailPath);

      // Find the coin element within the detail
      const coinElement = detailElement.querySelector('.coin');
      if (coinElement) {
        // Get the offset of the coin
        const coinRect = coinElement.getBoundingClientRect();
        const coinX = coinRect.left - sheetX;
        const coinY = coinRect.top - sheetY;

        // Calculate the radius for the coin circle
        const coinRadius = Math.min(coinRect.width, coinRect.height) / 2;

        // Create a circle element for the coin
        const coinCircle = document.createElementNS(svgNS, 'circle');
        // Set the circle attributes
        coinCircle.setAttribute('cx', coinX + coinRadius);
        coinCircle.setAttribute('cy', coinY + coinRadius);
        coinCircle.setAttribute('r', coinRadius);
        coinCircle.setAttribute('stroke', 'black');
        coinCircle.setAttribute('stroke-width', '1');
        coinCircle.setAttribute('fill', 'white');
        // Append the coin circle to the SVG
        svg.appendChild(coinCircle);
      }
    });
  });

  // Append the SVG to the document
//  document.body.appendChild(svg);

  saveSVG( svg );
}

// eslint-disable-next-line no-unused-vars
function saveSVG( svg ) {
    const svgData = new XMLSerializer().serializeToString(svg);
    const blob = new Blob([svgData], { type: "image/svg+xml" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "generated.svg";
    document.body.appendChild(a);
    a.click();

    // Clean up
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
}

const store = createStore({
  state: {
    sheets: {
      "one" : { groups: ['g1']
              }
    },
    groups: {
      'g1' : { details: ['s1'],
             }
    },
    details: {
      's1' : { data : { country : "The Bahamas",
                        date  : "1992",
                        value : "1 cent",
                        currency : "Dollar",
                        composition : "Cu plated Zn",
                        weight : "2.5g",
                        mint : "Royal Mint",
                        catalogue_num : "N#941",
                        grade : "",
                      },
               style : { height: "2",
                         width: "2",
                         window_diameter : ".875"
                       }
             },
    },
    settings: {
      defaults : {
        detail : { data : { country : "The Bahamas",
                            date  : "1992",
                            value : "1 cent",
                            currency : "Dollar",
                            composition : "Cu plated Zn",
                            weight : "2.5g",
                            mint : "Royal Mint",
                            catalogue_num : "N#941",
                            grade : "",
                          },
                   style : { height : "2",
                             width  : "2",
                           }
                 }
      },
      holder_sizes : [
        20,
        22,
        26,
        28,
        32,
        40
      ]
    },
    propertiesComponentName: null,
    propertiesItemUUID: null,
    showSettingsFlag: false
  },
  mutations: {
    //// Sheet mutations

    //// Group mutations
    setGroupDetailMode( state, groupUUID ){
      var group = state.groups[groupUUID];
      console.log('my group mode:'+group.detail_mode);
      for( var i=0; i<group.details.length; i++ ){
        var detail = state.details[group.details[i]];
        detail.style.mode = group.detail_mode;
      }
    },
    addGroup(state, sheetUUID){
      var sheet = state.sheets[sheetUUID];
      var groupUUID = uuid('group');
      state.groups[groupUUID] = { subheaders : [], details : [] };
      sheet.groups.push( groupUUID );
    },
    removeGroup(state, groupUUID){
      delete state.groups[groupUUID];
      for( var skey in state.sheets ){
        var sheet = state.sheets[skey];
        removeItemAll( sheet.groups, groupUUID );
      }
    },
    cloneGroup(state, groupUUID){
      var newGroupUUID = uuid('group');
      var newGroup = { subheaders : [], details : [] };
      var oldGroup = state.groups[groupUUID];
      // We have to clone the subheaders
      for( var idx in oldGroup.subheaders ){
        var hkey = oldGroup.subheaders[idx];
        newGroup.subheaders.push( cloneBase( state, hkey, 'subheader' ) )
      }
      // We have to clone the detail
      for( idx in oldGroup.details ){
        var skey = oldGroup.details[idx];

        newGroup.details.push( cloneBase( state, skey, 'detail' ) )
      }

      state.groups[newGroupUUID] = newGroup;

      for( var pkey in state.sheets ){
        var sheet = state.sheets[pkey];
        var origIdx = sheet.groups.indexOf(groupUUID);
        if( origIdx != -1 ){
          sheet.groups.splice(origIdx+1,0,newGroupUUID);
        }
      }
    },

    //// SubHeader mutations
    addSubHeader(state, groupUUID){
      var group = state.groups[groupUUID];
      var subheaderUUID = uuid('subheader');
      state.subheaders[subheaderUUID] = { text : 'Click to set text' };
      group.subheaders.push( subheaderUUID );
    },

    removeSubHeader(state, subheaderUUID){
      if( subheaderUUID == state.propertiesItemUUID ){
        state.propertiesItemUUID = undefined;
        state.propertiesComponentName = undefined;
      }
      delete state.subheaders[subheaderUUID];
      for( var gkey in state.groups ){
        var group = state.groups[gkey];
        removeItemAll( group.subheaders, subheaderUUID );
      }
    },

    //// Detail mutations
    addDetail(state, groupUUID){
      var group = state.groups[groupUUID];
      var detailUUID = uuid('detail');
      var data = Object.assign({},state.settings.defaults.detail.data);
      var style = Object.assign({},state.settings.defaults.detail.style);
      style.mode = group.detail_mode;
      state.details[detailUUID] = { data : data, style : style };

      state.details[detailUUID].uuid = detailUUID;
      group.details.push( detailUUID );
      console.log("detUUID:"+detailUUID);
      console.dir(state.details);
    },
//j    addDetailExtended(state, groupUUID){
//j      var group = state.groups[groupUUID];
//j      var detailUUID = uuid('detail');
//j      var data = Object.assign({},state.settings.defaults.detail.data);
//j      var style = Object.assign({},state.settings.defaults.detail.style);
//j      state.details[detailUUID] = { data : data, style : style };
//j      state.details[detailUUID].type = 'extended';
//j      console.dir(state.details);
//j      group.details.push( detailUUID );
//j    },
    removeDetail(state, detailUUID){
      if( state.propertiesItemUUID == detailUUID ){
        state.propertiesComponentName = undefined;
        state.propertiesItemUUID = undefined;
      }
      delete state.details[detailUUID];
      for( var gkey in state.groups ){
        var group = state.groups[gkey];
        removeItemAll( group.details, detailUUID );
      }
    },
    updateDetail( state, { updatedDetail, detailUUID } ){
      console.log("updateDetail, detailUUID:"+detailUUID);
      //state.details[detailUUID] = updatedDetail;
      state.details[detailUUID] = { ...state.details[detailUUID], ...updatedDetail };
    },
    cloneDetail(state, detailUUID){
      var newDetailUUID = uuid('detail');
      state.details[newDetailUUID] = Object.assign({},state.details[detailUUID]);
      for( var gkey in state.groups ){
        var group = state.groups[gkey];
        var origIdx = group.details.indexOf(detailUUID);
        if( origIdx != -1 ){
          group.details.splice(origIdx,0,newDetailUUID);
        }
      }
    },


    // General mutations
    setPropertiesComponent(state, { componentName, itemUUID }){
      // Can we highlight the calling component here?
      state.showSettingsFlag = false;
      state.propertiesComponentName = componentName;
      state.propertiesItemUUID = itemUUID;
    },
    updateStoreData(state, payload){
      Object.assign(state, payload.state);
    },
    showSettings(state){
      state.showSettingsFlag = true;
      state.propertiesComponentName = null;
      state.propertiesItemUUID = null;
    },
    clearData(state){
      state.sheets['one'].groups = [];
      state.details     = {};
      state.subheaders = {};
      state.groups     = {};
    }
  },
  actions: {
    saveStoreDataToFile(storeData) {
      const storeDataJSON = JSON.stringify(storeData);
      const blob = new Blob([storeDataJSON], { type: 'application/json' });
      const fileName = `store_data_${Date.now()}.json`;
    
      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = fileName;
      a.style.display = 'none';
    
      document.body.appendChild(a);
      a.click();
    
      document.body.removeChild(a);
    },
    loadStoreDataFromFile(state, handle_json, handle_csv) {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'application/json';
    
      input.addEventListener('change', (event) => {
        const file = event.target.files[0];
    
        if (file) {
          const reader = new FileReader();
          
          reader.onload = (e) => {
            const fileContents = e.target.result;
            try {
              const storeData = JSON.parse(fileContents);
              handle_json(storeData);
            } catch (error) {
              try {
                var csvData = parseCSV(fileContents);
                handle_csv(csvData);
              } catch ( csvError ){
                alert('Load requires a json or csv file');
                console.error('Error parsing JSON:', error);
              }
            }
          };
    
          reader.readAsText(file);
        }
      });
      input.click();
    },
    convertToSvg(){
//      console.log('convert to svg:'+store);
//      var state = store.state;
//      var uuid = Object.keys(state.sheets)[0];
//      var sheet = state.sheets[uuid];

      generateSVG()
//      var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg" );
//
//      svg.setAttribute("width", "200");
//      svg.setAttribute("height", "200");
//
//      // Create a path
//      var path = document.createElementNS("http://www.w3.org/2000/svg", "path" );
//      path.setAttribute("d", "M100 10 L150 190 L50 190 Z" );
//      path.setAttribute("fill", "none"); // No fill
//      path.setAttribute("stroke", "blue"); // Stroke color
//      path.setAttribute("stroke-width", "1"); // Stroke width
//
//      svg.appendChild( path );
//
//      document.getElementsByTagName('body')[0].append(svg);
//
//      for( var g=0; g<sheet.groups.length; g++ ){
//        var group = state.groups[sheet.groups[g]];
//        for( var d=0; d<group.details.length; d++ ){
//          var detail = state.details[group.details[d]];
//          var holder_width = getClosestHolder( state.settings.holder_sizes, 
//                                               parseInt(detail.data.diameter, 10 ) );
//          console.log("size:"+detail.data.diameter+', holder:'+holder_width );
//        }
//      }
    },
    fetchNumistaPage( state, { num, uuid } ){
      console.log("fetchNumistaPage num:"+num+",uuid:"+uuid);
      var detail = state.state.details[uuid];
      if( num ){
        var numista_url = 'https://en.numista.com/catalogue/pieces' + num +'.html';
        var url = 'https://corsproxy.io/?' + encodeURIComponent(numista_url);
        fetch( url )
          .then( response => {
            if( !response.ok ){
              throw new Error('Network response was not ok');
            }
            return response.text();
          })
          .then( html => {
            var parser = new DOMParser();
            var doc = parser.parseFromString(html, 'text/html');
            var features = doc.getElementById('fiche_caracteristiques');
            var pics = doc.getElementById('fiche_photo');
            var desc = doc.getElementById('fiche_descriptions');
//            var years = doc.getElementById('fiche_millesimes');
            console.log('here1');
            var pic_src_front = pics.getElementsByTagName('img')[0].getAttribute('src');
            var pic_src_back = pics.getElementsByTagName('img')[1].getAttribute('src');
            var rows = features.getElementsByTagName('tr');
            var numista_data = {};
            for( var i=0; i<rows.length; i++ ){
              var row   = rows[i];
              var th    = row.getElementsByTagName('th')[0];
              var td    = row.getElementsByTagName('td')[0];
              var key   = th.textContent.toLowerCase().replace(/\s+/g, ' ').trim();
              var value = td.textContent.replace(/\s+/g, ' ' ).trim();
              numista_data[key] = value;
            }

            var diameter_parts = numista_data.diameter.split(' ');

            var mintTitle = getElemByText( desc, 'h3', 'Mint' );
            console.log('mint');
            if( mintTitle ){
              var mintLink = mintTitle.nextSibling.nextSibling;
              var mintDesc = mintTitle.nextSibling.nextSibling.nextSibling;
              detail.data.mint_src  = mintLink.getAttribute('href');
              detail.data.mint_desc = mintLink.childNodes[0].nodeValue + ' ' + mintDesc.nodeValue.trim();
            }

            console.dir(numista_data);

            detail.data.country               = numista_data.issuer;
            detail.data.location              = numista_data.location;
            detail.data.date_range            = numista_data.years;
            detail.data.value                 = numista_data.value;
            detail.data.currency              = numista_data.currency;
            detail.data.composition           = numista_data.composition;
            detail.data.weight                = numista_data.weight;
            detail.data.diameter              = diameter_parts[0];
            detail.data.diameter_units        = diameter_parts[1];
            detail.data.numista_url           = numista_url;
            detail.data.numista_pic_src       = pic_src_front;
            detail.data.numista_pic_src_front = pic_src_front;
            detail.data.numista_pic_src_back  = pic_src_back;

            console.log('here2');
            console.dir(numista_data);

            var updatedDetail = detail;
            var detailUUID = uuid;
            state.commit( 'updateDetail', { updatedDetail, detailUUID } );
          });
      }
    }
  }
});

export default store;
