<template>
  <div class="sheet_properties">
    <div class="properties_header">Sheet Properties</div>
    <table>
      <tr>
        <td class="prompt_right">Country:</td>
        <td>
          <input v-model="sheet.country"/>
        </td>
      </tr>
      <tr>
        <td colspan=2>
          <button @click.stop="addGroup(propertiesItemUUID)">Add Group</button>
        </td>
      </tr>
      <tr>
        <td colspan=2>
          <button @click.stop="loadNumista()">Load Numista</button>
        </td>
      </tr>

    </table>
  </div>
</template>

<script>
import { mapState,mapMutations,mapActions } from 'vuex'
export default {
  name: 'SmSheetProperties',
  computed: {
    ...mapState(['sheets','propertiesItemUUID']),
    ...mapActions(['loadNumista']),
    sheet(){
      return this.sheets[this.propertiesItemUUID]
    }
  },
  methods: {
    ...mapMutations(['addGroup'])
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.sheet_properties {
}
.properties_header {
  font-weight     : bold;
  text-decoration : underline;
  margin-bottom   : 15px;
}
.prompt_right {
  text-align  : right;
}
</style>
