<template>
  <div class="subheader_properties">
    <div class="properties_header">SubHeader Properties</div>
    <table>
      <tr>
        <td class="prompt_right">Text:</td>
        <td>
          <input v-model="subheader.text" @blur="checkTextValue"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Bold:</td>
        <td style="text-align:left;">
          <input type=checkbox v-model="subheader.bold_flag"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Italic:</td>
        <td style="text-align:left;">
          <input type=checkbox v-model="subheader.italic_flag"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Underline:</td>
        <td style="text-align:left;">
          <input type=checkbox v-model="subheader.underline_flag"/>
        </td>
      </tr>
      <tr>
        <td colspan=2>
          <button @click.stop="removeSubHeader(propertiesItemUUID)">Remove</button>
        </td>
      </tr>

    </table>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'SmSubHeaderProperties',
  computed: {
    ...mapState(['subheaders','propertiesItemUUID']),
    subheader(){
      return this.subheaders[this.propertiesItemUUID]
    }
  },
  methods: {
    ...mapMutations(['updateSubHeader','removeSubHeader']),
    checkTextValue(){
      if( !this.subheader.text ){
        this.subheader.text = 'Click to set text (or remove)';
      }
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.subheader_properties {
}
.properties_header {
  font-weight     : bold;
  text-decoration : underline;
  margin-bottom   : 15px;
}
.prompt_right {
  text-align  : right;
}
</style>
