<template>
  <table>
    <tr>
      <td>
        <div class="detail">
          <div class="container">
            <div class="top"> 
              <div class="cell left">
                <div>country</div>
                <div>value</div>
              </div>
              <div class="cell right">
                <div>mint</div>
                <div>date</div>
              </div>
            </div>
            <div class="mid">
              <div class="cell"/>
              <div class="cell" style="display:flex;">
              </div>
              <div class="cell"/>
            </div>
            <div class="bot"> 
              <div class="cell left" style="position:absolute;bottom:5px;height:auto;">
                <div>weight</div>
                <div>composition</div>
              </div>
              <div class="cell right" style="position:absolute;bottom:5px;right:5px;height:auto;">
                catalogue_num
              </div>
            </div>
          </div>
        </div>
      </td>
      <td style="vertical-align:top;">
        <ul>
          <li draggable="true">country</li>
          <li draggable="true">value</li>
          <li draggable="true">mint</li>
          <li draggable="true">date</li>
          <li draggable="true">weight</li>
          <li draggable="true">composition</li>
          <li draggable="true">catalogue num</li>
        </ul>
      </td>
    </tr>
  </table>
</template>

<script>

import { removeClassFromElements } from '../utils.js';
export default {
  name: 'SmDetailLayout',
  mounted(){
    const elements = document.querySelectorAll('.cell');
    elements.forEach((element) => {
      element.classList.add('initialized');
      element.addEventListener('dragenter', this.dragenter_handler);
      element.addEventListener('dragleave', this.dragleave_handler);
      element.addEventListener('drop', this.drop_handler);
    } );
  },
  methods: {
    dragstart_handler(e){
      e.preventDefault();
      console.dir(e);
      e.dataTransfer.setData("text/plain", e.target.id);
    },
    dragenter_handler(e){
      e.preventDefault();
      console.log('enter:'+Date());
      console.dir(e);
      removeClassFromElements('.cell', 'hover' );
      console.dir(e.currentTarget);
      e.currentTarget.classList.add('hover');
    },
    dragleave_handler(e){
      e.preventDefault();
      console.log('leave:'+Date());
      e.target.classList.add('hover');
    },
    drop_handler(e){
      e.preventDefault();
      console.dir(e);
    }
  },
  components: {
  },
  props: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

ul {
  text-align: left;
}
.detail {
  width          : 2in;
  height         : 2in;
  border-style   : dashed;
  border-width   : 1px;
  vertical-align : middle;
  display        : inline-block;
  position       : relative;
  cursor         : pointer;
  font-size      : small;
  padding        : 5px;
}

.container {
  display        : flex;
  flex-direction : column;
  height         : 100%;
  width          : 100%;
  position       : relative;
}

.top { 
  flex           : 1;
  flex-basis     : 100%;
  display        : flex;
  flex-direction : row;
  border         : dashed 1px green;
}

.mid {
  flex           : 1;
  flex-basis     : 100%;
  display        : flex;
  flex-direction : row;
  border         : dashed 1px red;
}

.bot {
  flex           : 1;
  flex-basis     : 100%;
  display        : flex;
  flex-direction : row;
  border         : dashed 1px yellow;

  position       : relative;
  vertical-align : bottom;
}

.cell {
  flex           : 1;
  height         : 100%;
  vertical-align : inherit;
  border         : dotted 1px white;
}

.cell .hover {
  background-color : pink;
}

.left {
  text-align : left;
}

.right {
  text-align : right;
}

img.coin {
  width : var(--coin-diameter);
  height : var(--coin-diameter);
}

div.coin {
  border-radius : 50%;
  background-color : gray;
  margin : auto;
  width : var(--coin-diameter);
  height : var(--coin-diameter);
}

@media print {
  .coin {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: gray;
  }
}

li {
  cursor : pointer;
}

</style>
