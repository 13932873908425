<template>
  <div class="group_properties">
    <div class="properties_header">Group Properties</div>
    <table>
      <tr>
        <td class="prompt_right">Mode:</td>
        <td>
          <button @click.stop="setDetailMode('two_by_two')">2x2</button>
          <button @click.stop="setDetailMode('detailed')">Detailed</button>
        </td>
      </tr>
      <tr>
        <td colspan=2>
          <button @click.stop="addDetail(propertiesItemUUID)">Add detail</button>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'SmGroupProperties',
  computed: {
    ...mapState(['groups','propertiesItemUUID']),
    group(){
      return this.groups[this.propertiesItemUUID]
    }
  },
  methods: {
    ...mapMutations(['addDetail','addDetailExtended','setDetailMode','setGroupDetailMode']),
    setDetailMode( mode ){
      this.group.detail_mode = mode;
      this.setGroupDetailMode( this.propertiesItemUUID );
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.group_properties {
}
.properties_header {
  font-weight     : bold;
  text-decoration : underline;
  margin-bottom   : 15px;
}
.prompt_right {
  text-align  : right;
}
</style>
