<template>
  <div class="detail" :class="{ 'highlighted': detailUUID === propertiesItemUUID }" @click.stop="callSetProperties('SmDetailProperties',detailUUID)" :style="computedStyles">
    <div class="container">
      <div class="top"> 
        <div class="cell left">
          <div>{{detail.data.country}}</div>
          <div>{{detail.data.value}}</div>
        </div>
        <div class="cell right">
          <div>{{detail.data.mint}}</div>
          <div>{{detail.data.date}}</div>
        </div>
      </div>
      <div class="mid">
        <div class="cell"/>
        <div class="cell" style="display:flex;">
          <div v-if="detail.data.numista_pic_src==undefined" class="coin"></div>
          <img v-if="detail.data.numista_pic_src!=undefined" :src="detail.data.numista_pic_src" class="coin"/>
        </div>
        <div class="cell"/>
      </div>
      <div class="bot"> 
        <div class="cell left" style="position:absolute;bottom:5px;height:auto;">
          <div>{{detail.data.weight}}</div>
          <div>{{detail.data.composition}}</div>
        </div>
        <div class="cell right" style="position:absolute;bottom:5px;right:5px;height:auto;">
          {{detail.data.catalogue_num}}
        </div>
      </div>
  
      <RemoveBtn @click.stop="removeDetail(detailUUID)" style="top:25px;"/>
      <CloneBtn  @click.stop="cloneDetail(detailUUID)" style="bottom:20px;"/>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import RemoveBtn from './RemoveBtn'
import CloneBtn from './CloneBtn'
export default {
  name: 'SmDetail',
  components: {
    RemoveBtn,
    CloneBtn
  },
  computed: {
    ...mapState(['details','propertiesItemUUID']),
    detail(){
      console.log('get detail:'+this.detailUUID)
      console.dir(this.details);
      return this.details[this.detailUUID]
    },
    computedStyles(){
      console.log('....styles');
      return {
        '--detail-height' : (this.detail.style.height||2) + 'in',
        '--detail-width' : (this.detail.style.width||2) + 'in',
        '--coin-diameter' : (this.detail.style.window_diameter||.5) + 'in',
      };
    }
  },
  methods: {
    ...mapMutations(['removeDetail','setPropertiesComponent','cloneDetail']),
    callSetProperties( componentName, itemUUID ){
      this.setPropertiesComponent( {componentName, itemUUID} )
    },
  },
  props: {
    detailUUID: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.detail {
  width          : var(--detail-width);
  height         : var(--detail-height);
  border-style   : dashed;
  border-width   : 1px;
  vertical-align : middle;
  display        : inline-block;
  position       : relative;
  cursor         : pointer;
  font-size      : small;
  padding        : 5px;
}

.container {
  display : flex;
  flex-direction : column;
  height : 100%;
  width : 100%;
  position : relative;
}

.top { 
  flex : 1;
  flex-basis : 100%;

  display: flex;
  flex-direction : row;
}

.mid {
  display: flex;
  flex-direction : row;
  flex : 1;
  flex-basis : 100%;
}

.bot {
  flex : 1;
  flex-basis : 100%;
  vertical-align : bottom;

  display: flex;
  flex-direction : row;
  position : relative;
}

.cell {
  flex : 1;
  height : 100%;
  vertical-align : inherit;
}

.left {
  text-align : left;
}

.right {
  text-align : right;
}

img.coin {
  width : var(--coin-diameter);
  height : var(--coin-diameter);
}

div.coin {
  border-radius : 50%;
  background-color : gray;
  margin : auto;
  width : var(--coin-diameter);
  height : var(--coin-diameter);
}

@media print {
  .coin {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    background-color: gray;
  }
}

</style>
