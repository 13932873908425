<template>
  <div class="stamp_properties">
    <div class="properties_header">Detail Properties</div>
    <table>
      <tr>
        <td class="prompt_right">Country:</td>
        <td class="data">
          <input v-model="detail.data.country"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Date:</td>
        <td class="data">
          <input v-model="detail.data.date"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">&nbsp;</td>
        <td class="data">
          <span style="font-style:italic;font-size:smaller;">{{detail.data.date_range}}</span>
        </td>
      </tr>

      <tr>
        <td class="prompt_right">Value:</td>
        <td class="data">
          <input v-model="detail.data.value"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Currency:</td>
        <td class="data">
          <input v-model="detail.data.currency"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Composition:</td>
        <td class="data">
          <input v-model="detail.data.composition"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Weight:</td>
        <td class="data">
          <input v-model="detail.data.weight"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Mint:</td>
        <td class="data">
          <input v-model="detail.data.mint"/>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Catalog num:</td>
        <td class="data">
          <input v-model="detail.data.catalogue_num"/>
          &nbsp;
          <button @click.stop="callFetchNumistaPage">Fetch</button>
          <button @click.stop="openNumista">Numista</button>
        </td>
      </tr>
      <tr>
        <td class="prompt_right">Window diameter:</td>
        <td class="data">
          <input v-model="detail.style.window_diameter"/>
        </td>
      </tr>

    </table>
  </div>
</template>

<script>
import { mapState,mapActions } from 'vuex'
export default {
  name: 'SmDetailProperties',
  computed: {
    ...mapState(['details','propertiesItemUUID']),
    detail(){
      return this.details[this.propertiesItemUUID]
    },
  },
  methods: {
    ...mapActions(['fetchNumistaPage']),
    callFetchNumistaPage(){
      console.log('number:'+this.detail.data.catalogue_num)
      var num = this.detail.data.catalogue_num
      var uuid = this.propertiesItemUUID
      console.log('num:'+num+',uuid:'+uuid);

      this.fetchNumistaPage( { num, uuid } )
    },
    openNumista(){
      window.open(this.detail.data.numista_url, '_blank' );
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.stamp_properties {
}
.properties_header {
  font-weight     : bold;
  text-decoration : underline;
  margin-bottom   : 15px;
}
</style>
